import React from "react";
import { Link, withRouter } from "react-router-dom";

function Navigation(props) {
  return (
    <header className="navigation">
      <nav className="navbar navbar-expand">
        <div className="container">
          <Link className="navbar-brand main-logo" to="/">applify</Link>
          <div>
            <ul className="navbar-nav ml-auto">

              {/* <li class={`nav-item  ${props.location.pathname === "/" ? "active" : ""}`}>
                <Link class="nav-link" to="/">Home</Link>
              </li>
              <li class={`nav-item  ${props.location.pathname === "/about" ? "active" : ""}`}>
                <Link class="nav-link" to="/about">About</Link>
              </li> */}
              <li className={`nav-item  ${props.location.pathname === "/contact" ? "active" : ""}`}>
                <Link className="nav-link px-0" to="/contact">Contact us</Link>
              </li>

            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default withRouter(Navigation);