import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <footer className="py-5 fixed-bottom">
        <div className="container">
          <p className="copyright-text m-0 text-center">Copyright &copy; Applify 2021</p>
        </div>
      </footer>
    </footer>
  );
}

export default Footer;