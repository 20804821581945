import React from "react";
import gradient from '../images/gradient.png';

function Home() {
  return (
    <div className="home position-relative">
      <img className="animated-gradient" src={gradient} alt="Rotating gradient" />
      <div className="container">
        <div className="row mt-5 mb-4 text-center text-md-start">
          <div className="col-12">
            <div className="title-container">
              <h1>We create memorable experiences</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 text-center text-md-start">
            <p>We’re currently brewing Apps. Our goal is to design and develop highly scalable mobile apps that are feature-rich and attract loyal long-term users.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;